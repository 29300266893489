.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  min-height: 125px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  .l-wrapper {
    padding: 25px 35px;
    background-color: white;
    border: 1px solid white;
    width: 100%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-el {
    cursor: pointer;
    &:first-child {
      transform: translateY(5px);
    }

    &:last-child {
      transform: translateY(-5px);
    }

    svg {
      transform: rotate(23deg) scaleX(2);
      width: 20px;
    }
  }

  .main-menu {
    background-color: white;
    left: 0;
    min-height: calc(100vh - 125px);
    opacity: 0;
    padding: 25px;
    pointer-events: none;
    position: absolute;
    top: 125px;
    width: 100vw;
    z-index: 100;

    &.m--is-open {
      opacity: 1;
      pointer-events: all;
      transition: opacity 300ms ease;
    }

    .top-links{
      font-size: rem(15);
      font-weight: 600;
      &__link {
        padding: 15px;
        color: white;
        text-align: center;

        &.m--blue {
          background: $blue;
        }

        &.m--light-blue {
          background: $blue-gray;
        }

      }
      .main-menu__wrapper {
        margin-top: 25px;

        a {
          text-align: center;
          margin : 20px 0;
          font-size: rem(18);
        }
      }
    }
  }
}


@media all and (min-width : $bp-l) {
  .header {
    align-items: flex-start;
    position: fixed;
    .header__top {
      width: 250px;
      padding: 16px 36px;
    }
    .header__top a img {
      width: 170px;
    }
    .l-wrapper {
      border: 0;
      display: flex;
      justify-content: space-between;
      padding: 0;
    }

    .header__open-menu {
      display: none;
    }

    .main-menu {
      display: block;
      position: static;
      min-height: inherit;
      opacity: 1;
      pointer-events: all;
      width: inherit;
    }

    .main-menu {
      padding: 0;
      .top-links {
        display: flex;
        justify-content: flex-end;

        &__link {
          padding: 15px 35px;
          width: max-content;
        }

        .main-menu__wrapper {
          display: flex;
          justify-content: flex-end;
          padding: 12px 60px 16px;
          margin-top: 0;
          a {
            margin: 0px 0px 0px 50px;
            font-size: 16px;
            line-height: 22px;
            font-weight: $fw-regular;
            width: max-content;
            &:hover {
              color: $blue-gray;
              transition: 300ms ease;
            }
          }
        }
      }
    }
  }
}
