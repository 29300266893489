.services {
  padding: 0 12px;
  .h2-like {
    padding-left: 24px;
    padding-bottom: 68px;
    &::before {
      left: 68px;
      top: calc(50% - 21px);
    }
  }
  .services__service {
    color: white;
    text-align: center;
    padding: 60px 60px 40px;
    &.m--gray {
      background-color: #ECEFF1;
      color: $blue;
    }
    &.m--light-blue {
      background-color: $blue-l;
    }
    &.m--blue {
      background-color: $blue;
    }
    h4 {
      font-size: 30px;
      font-weight: $fw-bold;
      padding-bottom: 50px;
    }
    ul {
      font-size: 20px;
      line-height: 24px;
      li {
        padding-bottom: 32px;
      }
    }
  }
}

@media all and (min-width: $bp-l){
  .services {
    padding: 120px 0 110px;
    .services__wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .title-section {
      width: 100%;
      padding-left: 0;
      text-align: center;
      &::before {
        left: calc(50% - 50px);
      }
    }
    .services__service {
      transform: translateY(-90px);
      width: calc(33.33% - 16px);
      margin: 0 8px;
      &:nth-child(3){
        transform: translateY(0);
      }
    }
  }
}
