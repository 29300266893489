.social {
  background-color: $blue;
  .crt-widget .crt-post-text {
    color: white;
    text-align: left;
    padding-top: 18px;
  }
  .crt-post-header {
    display: none;
  }
  .crt-post-footer {
    display: none;
  }
}
