.jobs-list {
  margin-bottom: 50px;
  .jobs-list__title {
    margin-bottom: 60px;
    padding-left: 30px;
    &::before {
      left: 30px;
    }
  }
  .jobs-list__col-left, .jobs-list__location {
    position: relative;
  }
  .jobs-list__col-left {
    img {
      max-width: 100px;
    }
  }
  .jobs-list__title-box {
    margin-top: 30px;
    h3 {
      font-size: 26px;
      padding: 8px 0;
    }
  }
  .jobs-list__small {
    font-size: 12px;
    font-weight: $fw-bold;
  }
  .jobs-list__city {
    font-size: 22px;
  }

  .jobs-list__item-wrapper {
    position: relative;
    padding: 34px 52px 41px 41px;
    background: linear-gradient(180deg, rgba($blue-gray, .14), rgba($blue-gray, .06) 60%, rgba($blue-gray, 0) 100%);
  }
  .jobs-list__location-wrapper * {
    display: inline-block;
    vertical-align: middle;
    &.jobs-list__small {
      padding-left: 18px;
    }
  }
  .btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 24px 0;
  }
  .jobs-list__item {
    display: none;
  }
  .jobs-list__item:nth-child(-n+6) {
    display: block;
  }
}

.featured-offers {
  padding: 35px 0;
  .h2-like {
    margin-left: 35px;
    margin-bottom: 42px;
  }
}

.jobs-list__spontaneous {
  text-align: center;
  padding-top: 32px;
  .btn {
    left: 0;
    transform: none;
  }
    p {
      font-size: 20px;
      line-height: 32px;
  }

  @media all and (min-width: $bp-l){
    p {
      font-size: 22px;
      line-height: 30px;
    }
  }
}

@media all and (min-width: $bp-l){
  .jobs-list {
    .jobs-list__col-left {
      padding: 40px 16px;
      img {
        max-width: 120px;
      }
    }
    .jobs-list__title-box {
      margin-top: 0;
      padding-left: 30px;
      position: relative;
    }
    .jobs-list__location {
      padding: 40px 30px;
    }
    .jobs-list__col-left {
      display: flex;
      width: 65%;
    }
    .jobs-list__item-wrapper {
      display: flex;
    }
    .jobs-list__location {
      border-left: solid 6px white;
    }
    .jobs-list__col-left, .jobs-list__location {
      &::before {
        content: '';
        opacity: .14;
        position: absolute;
        display: block;
        height: 100%;
        width: 100vw;
        top: 0;
        background: linear-gradient(180deg, rgba($blue-gray, 1), rgba($blue-gray, .73) 60%, rgba($blue-gray, 0) 100%);
        transition: background .2s ease;
      }
      &.jobs-list__col-left::before {
        right: 0;
      }
      &.jobs-list__location::before {
        left: 0;
      }
    }
    .jobs-list__item-wrapper:hover {
      .jobs-list__col-left::before, .jobs-list__location::before {
        background: white;
      }
    }
    .jobs-list__item-wrapper {
      background: none;
      padding: 0;
    }
    .jobs-list__location-wrapper {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column-reverse;
      .jobs-list__small {
        padding-left: 0;
      }
    }
  }
}
