/**
 * ==================== @ SASS MIXINS
 */

// Sizing helper
// @access public
// @param {Length} $width - Width
// @param {Length} $height [$width] - Height
// @example scss - Usage
//   .foo {
//     @include size(350px);
//   }
// @example css - Result
//   .foo {
//     width: 350px;
//     height: 350px;
//   }
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

// Shorthandizes position declarations.
// @access public
// @param {String} $type - Either `relative`, `absolute` or `fixed`
// @param {Length} $left [null] - Left offset
// @param {Length} $right [null] - Right offset
// @param {Length} $top [null] - Top offset
// @param {Length} $bottom [null] - Bottom offset
// @example scss - Usage
//   .foo {
//     @include position(absolute, $top: 10px, $left: 10px);
//   }
// @example css - Result
//   .foo {
//     position: absolute;
//     left: 10px;
//     top: 10px;
//   }
@mixin position($type, $top: null, $right: null, $bottom: null, $left: null) {
    position: $type;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

// https://www.sassmeister.com/gist/7f22e44ace49b5124eec
// =========================================================================
//
//  PRECISE CONTROL OVER RESPONSIVE TYPOGRAPHY FOR SASS
//  ---------------------------------------------------
//  Indrek Paas @indrekpaas
//
//  Inspired by Mike Riethmuller's Precise control over responsive typography
//  http://madebymike.com.au/writing/precise-control-responsive-typography/
//
//  `strip-unit()` function by Hugo Giraudel
//
//  11.08.2016 Remove redundant `&` self-reference
//  31.03.2016 Remove redundant parenthesis from output
//  02.10.2015 Add support for multiple properties
//  24.04.2015 Initial release
//
// =========================================================================

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media all and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media all and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@mixin object-fit($size) {
  font-family: "object-fit: #{$size};";
  object-fit: $size;
}

// SVG as background
@mixin background-svg($svg, $fill: null){
  background-color: transparent;
  background-image: svg-url($svg, $fill);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin icon-size($icon-size, $context: $icon-size){
  padding: calc((100% - #{percentage(strip-unit($icon-size/$context))}) / 2);
}
