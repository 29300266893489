/**
 * ==================== @ BASE STYLES
 */

// CSS Reset
html {
  box-sizing: border-box;
  font-size: $default-font-size;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  background-color: #fff;
  color: $blue;
  font: normal $fw-regular 1em $default-font-stack;
  overflow-x: hidden;
  transition: opacity .2s ease;
  opacity: 1;
}

body.hidden {
  opacity: 0;
}

p {
  line-height: 1.7;
}


a {
  color: inherit;
  text-decoration: none;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

iframe {
  border: 0 none;
}

img {
  height: auto;
  max-width: 100%;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

[data-href] {
  cursor: pointer;
}


// Text
.h1-like, .h2-like, .h3-like, .h4-like {
  font-weight: $fw-bold;
  line-height: 1.2;
}

.h1-like {
  font-size: rem(35);
}

.h2-like {
  font-size: rem(30);
}

.h3-like {
  font-size: rem(18);
}

.h4-like {
  font-size: rem(16);
}

.sv-g {
  font-family: $default-font-stack;
}

.btn {
  background-color: transparent;
  border: solid 1px $blue;
  display: inline-block;
  padding: 28px 113px 28px 28px;
  font-weight: $fw-bold;
  position: relative;
  transition: background-color .2s ease;
  &::after {
    content: '';
    @include background-svg($arrow, $blue);
    width: 45px;
    height: 18px;
    display: inline-block;
    position: absolute;
    right: 28px;
    margin-left: 40px;
    top: calc(50% - 9px);
  }
  &:hover {
    color: white;
    background-color: $blue;
    text-decoration: none!important;
    &::after {
      @include background-svg($arrow, white);
    }
  }
}

.text-simple {
  padding: 32px;
}

@media all and (min-width: $bp-l) {

  .h1-like {
    font-size: rem(50);
  }

  .h2-like {
    font-size: rem(32);
  }

  .h3-like {
    font-size: rem(24);
  }

  .h4-like {
    font-size: rem(20);
  }
}

