.job-offer {

}

.job-offer-head {
  .job-offer-head__contract-type {
    font-size: 35px;
    font-weight: $fw-bold;
  }
  .job-offer__location-city {
    font-size: 22px;
    font-weight: $fw-bold;
    display: block;
  }
  .job-offer__location-department {
    font-size: 12px;
    font-weight: $fw-bold;
    display: block;
  }
  .job-offer-head__company {
    font-size: 22px;
    font-weight: $fw-bold;
  }
  .job-offer-head__job-infos {
    margin-top: 30px;
    .svg-icon {
      display: none;
    }
    span {
      display: block;
      font-size: 22px;
      line-height: 30px;
    }
  }
  .job-offer-head__company {
    display: block;
  }
}

.job-offer__content {
  p, ul {
    font-size: 22px;
    line-height: 30px;
  }
  .h3-like {
    font-size: 25px;
    padding: 30px 0;
  }
  p.m--ptop {
    padding-top: 32px;
  }
}
.job-offer-head__line {
  border-bottom: solid 1px $blue;
  padding: 26px 0;
}

.job-offer__wrapper {
  padding: 0 36px;
}

.job-offer-form, .contact-form {
  background-position: center;
  background-size: cover;
  padding: 36px;
  position: relative;
  &::before {
    content: "";
    background: url('/assets/images/line-bg-white.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    opacity: .24;
  }
  &::after {
    content: "";
    display: block;
    background: linear-gradient(180deg, rgba($blue-gray, .14), rgba($blue-gray, .06) 60%, rgba($blue-gray, 0) 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
  .l-wrapper {
    position: relative;
    z-index: 1;
  }
}

@media all and (min-width: $bp-l){
  .job-offer__wrapper {
    background-color: #fff;
    padding: 72px 92px 92px;
    position: relative;
    transform: translateY(-176px);
    margin-bottom: -176px;
    z-index: 1;
  }
  .job-offer-head {
    .job-offer-head__line {
      display: flex;
      align-items: center;
    }
    .job-offer__location {
      display: flex;
      flex-direction: column-reverse;
    }
    .job-offer-head__job-infos {
      margin-top: 0;
      justify-self: flex-end;
      margin-left: auto;
      span {
        display: inline-block;
      }
      .svg-icon {
        display: inline-block;
        color: $blue-gray;
        margin: 0 15px;
      }
    }
    .job-offer-head__company {
      margin-left: auto;
      display: inline-block;
    }
  }
}
