/**
 * ==================== @ GENERIC MODIFIERS
 */

.m--title-deco {
  padding: 32px 0;
  position: relative;
  &::before {
    content: '';
    @include background-svg($title_deco, $blue-gray);
    opacity: .15;
    position: absolute;
    display: block;
    height: 100px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100px;
    z-index: 10;
  }

  &.m--white {
    &::before {
      @include background-svg($title_deco, white);
    }
  }
}

.m--bg-dark {
  background : $blue;
  color: white;
}
