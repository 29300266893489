.text-wide {
  p {
    padding: 38px;
    font-size: 20px;
    line-height: 32px;
    a {
      font-size: 16px;
    }
  }
}

@media all and (min-width: $bp-l){
  .text-wide {
    p {
      padding: 74px 92px;
      font-size: 22px;
      line-height: 30px;
      a {
        font-size: 16px;
      }
    }
  }
}
