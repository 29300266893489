.map {
  padding-left: 22px;
  .sv-u-1:last-child {
    pointer-events: none;
  }
  .map__map {
    transform: translateY(-25%);
    margin-bottom: -40%;
    overflow: hidden;
    svg {
      max-width: 150%;
      #brest, #plabennec, #morlaix, #saint-brieuc, #rennes, #nantes, #vannes, #lorient, #quimper {
        transition: opacity .2s ease;
      }
      &.m--groupe {
        #brest, #morlaix, #saint-brieuc, #rennes, #nantes, #vannes, #lorient, #quimper {
          opacity: 0;
        }
      }
      &.m--energie {
        .logo-leaf {
          fill: #B4C422!important;
        }
        #plabennec {
          opacity: 0;
        }
      }
      &.m--habitat {
        .logo-leaf {
          fill: #EF710C!important;
        }
        #plabennec, #morlaix, #vannes {
          opacity: 0;
        }
      }
    }
  }
  .map__text {
    padding: 40px 36px 50px 14px;
    font-weight: $fw-light;
    font-size: 16px;
    line-height: 26px;
  }
  .map__filters {
    margin-top: 35px;
    h4 {
      margin-bottom: 14px;
    }
    button {
      background-color: transparent;
      border: solid 1px $blue;
      color: $blue;
      display: block;
      font-weight: $fw-bold;
      margin-bottom: 8px;
      padding: 14px 22px;
      transition: color .2s ease;
      transition-property: color, background-color;
      &:hover, &.m--active {
        background-color: $blue;
        color: white;
      }
    }
  }
}

@media all and (min-width: $bp-l){
  .map {
    padding: 100px 0;
    background: linear-gradient(180deg, rgba($blue-gray, .14), rgba($blue-gray, .1) 60%, rgba($blue-gray, 0) 100%);
    .map__map {
      transform: none;
      position: relative;
      top: -100px;
      overflow: visible;
    }
    .map__text {
      padding-right: 80px;
    }
    .map__filters {
      margin-top: 85px;
      width: 200%;
      h4, button {
        display: inline-block;
      }
      h4 {
        margin-right: 40px;
      }
      button {
        margin-right: 9px;
      }
    }
  }
}
