/**
 * Use BEM : https://en.bem.info/
 */
@import url(pure.css);
@import url(../../node_modules/swiper/swiper-bundle.min.css);
@import url(../../node_modules/magnific-popup/dist/magnific-popup.css);
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0; }

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option--selectable {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative; }

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--group {
  padding: 0; }

.select2-container--default .select2-results__option--disabled {
  color: #999; }

.select2-container--default .select2-results__option--selected {
  background-color: #ddd; }

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option--group {
  padding: 0; }

.select2-container--classic .select2-results__option--disabled {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: #fff; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/**
 * ==================== @ THEME VARIABLES
 */
/**
 * ==================== @ SASS FUNCTIONS
 */
/**
 * ==================== @ SASS MIXINS
 */
/**
 * ==================== @ SASS PLACEHOLDERS
 */
.l-clearfix::before, .l-clearfix::after {
  content: " ";
  display: table; }

.l-clearfix::after {
  clear: both; }

/**
 * Fonts
 */
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v23-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v23-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v23-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v23-latin-regular.woff") format("woff"), url("../fonts/montserrat-v23-latin-regular.ttf") format("truetype"), url("../fonts/montserrat-v23-latin-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v23-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/montserrat-v23-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v23-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v23-latin-700.woff") format("woff"), url("../fonts/montserrat-v23-latin-700.ttf") format("truetype"), url("../fonts/montserrat-v23-latin-700.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/**
 * ==================== @ BASE STYLES
 */
html {
  box-sizing: border-box;
  font-size: 14px; }

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0; }

body {
  background-color: #fff;
  color: #1C394B;
  font: normal 400 1em "Montserrat", sans-serif;
  overflow-x: hidden;
  transition: opacity .2s ease;
  opacity: 1; }

body.hidden {
  opacity: 0; }

p {
  line-height: 1.7; }

a {
  color: inherit;
  text-decoration: none; }

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none; }

figure {
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

iframe {
  border: 0 none; }

img {
  height: auto;
  max-width: 100%; }

ol,
ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

[data-href] {
  cursor: pointer; }

.h1-like, .h2-like, .typo h2, .h3-like, .typo h3, .h4-like, .typo h4 {
  font-weight: 700;
  line-height: 1.2; }

.h1-like {
  font-size: 2.5rem; }

.h2-like, .typo h2 {
  font-size: 2.14286rem; }

.h3-like, .typo h3 {
  font-size: 1.28571rem; }

.h4-like, .typo h4 {
  font-size: 1.14286rem; }

.sv-g {
  font-family: "Montserrat", sans-serif; }

.btn, .text-img .entry a {
  background-color: transparent;
  border: solid 1px #1C394B;
  display: inline-block;
  padding: 28px 113px 28px 28px;
  font-weight: 700;
  position: relative;
  transition: background-color .2s ease; }
  .btn::after, .text-img .entry a::after {
    content: '';
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 74 32'%3E%3Cpath fill='%231C394B' d='M-0.013 16.011c0.007 0.437 0.363 0.788 0.8 0.788 0.004 0 0.009-0 0.013-0h72.789c0.003 0 0.007 0 0.011 0 0.442 0 0.8-0.358 0.8-0.8s-0.358-0.8-0.8-0.8c-0.004 0-0.008 0-0.012 0h-72.789c-0.004-0-0.008-0-0.013-0-0.442 0-0.8 0.358-0.8 0.8 0 0.004 0 0.009 0 0.013v-0.001z'%3E%3C/path%3E%3Cpath fill='%231C394B' d='M57.911 30.876c0 0.442 0.358 0.8 0.8 0.8 0.226 0 0.429-0.093 0.575-0.244l14.867-14.867c0.145-0.145 0.234-0.345 0.234-0.566s-0.090-0.421-0.234-0.566l-14.867-14.867c-0.146-0.152-0.351-0.246-0.577-0.246-0.442 0-0.8 0.358-0.8 0.8 0 0.227 0.094 0.431 0.246 0.577l14.301 14.301-14.301 14.301c-0.15 0.146-0.244 0.35-0.244 0.575 0 0 0 0 0 0v0z'%3E%3C/path%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 45px;
    height: 18px;
    display: inline-block;
    position: absolute;
    right: 28px;
    margin-left: 40px;
    top: calc(50% - 9px); }
  .btn:hover, .text-img .entry a:hover {
    color: white;
    background-color: #1C394B;
    text-decoration: none !important; }
    .btn:hover::after, .text-img .entry a:hover::after {
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 74 32'%3E%3Cpath fill='white' d='M-0.013 16.011c0.007 0.437 0.363 0.788 0.8 0.788 0.004 0 0.009-0 0.013-0h72.789c0.003 0 0.007 0 0.011 0 0.442 0 0.8-0.358 0.8-0.8s-0.358-0.8-0.8-0.8c-0.004 0-0.008 0-0.012 0h-72.789c-0.004-0-0.008-0-0.013-0-0.442 0-0.8 0.358-0.8 0.8 0 0.004 0 0.009 0 0.013v-0.001z'%3E%3C/path%3E%3Cpath fill='white' d='M57.911 30.876c0 0.442 0.358 0.8 0.8 0.8 0.226 0 0.429-0.093 0.575-0.244l14.867-14.867c0.145-0.145 0.234-0.345 0.234-0.566s-0.090-0.421-0.234-0.566l-14.867-14.867c-0.146-0.152-0.351-0.246-0.577-0.246-0.442 0-0.8 0.358-0.8 0.8 0 0.227 0.094 0.431 0.246 0.577l14.301 14.301-14.301 14.301c-0.15 0.146-0.244 0.35-0.244 0.575 0 0 0 0 0 0v0z'%3E%3C/path%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }

.text-simple {
  padding: 32px; }

@media all and (min-width: 64em) {
  .h1-like {
    font-size: 3.57143rem; }
  .h2-like, .typo h2 {
    font-size: 2.28571rem; }
  .h3-like, .typo h3 {
    font-size: 1.71429rem; }
  .h4-like, .typo h4 {
    font-size: 1.42857rem; } }

/**
 * ==================== @ GENERIC FLOW TOOLS
 */
.l-wrapper {
  margin: 0 auto;
  max-width: 1080px; }
  .l-wrapper.m--s {
    max-width: 590px; }

.vc_row.l-wrapper {
  margin: 0 auto !important; }

.l-padding {
  padding: 0 36px; }

.l-unpad {
  margin: 0 -32px; }

.l-center {
  text-align: center; }

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0; }

.l-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.l-section {
  padding: 45px 35px; }

.l-scrollbar {
  position: relative; }
  .l-scrollbar.ps .ps__rail-y {
    background-color: rgba(188, 188, 188, 0.5);
    border-radius: 15px;
    left: 0;
    opacity: 1;
    overflow: hidden;
    right: auto;
    transition: width .25s;
    width: 6px; }
    .l-scrollbar.ps .ps__rail-y:hover, .l-scrollbar.ps .ps__rail-y:focus, .l-scrollbar.ps .ps__rail-y.ps--clicking {
      width: 15px; }
  .l-scrollbar.ps .ps__thumb-y {
    background-color: #1C394B;
    right: 0;
    width: 100%; }
  .l-scrollbar.m--right .ps__rail-y {
    left: auto;
    right: 0; }

.l-mobile-hidden {
  display: none !important; }

body.overlay {
  overflow: hidden; }

@media all and (min-width: 64em) {
  .l-desktop-hidden {
    display: none !important; }
  .l-mobile-hidden {
    display: inline-block !important; } }

/**
 * ==================== @ STATES
 */
.is-semantic {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  display: block;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

/**
 * ==================== @ TYPO
 */
.typo {
  line-height: 1.5; }
  .typo * + p {
    margin-top: 1em; }
  .typo h2:not(:first-child), .typo h3:not(:first-child), .typo h4:not(:first-child), .typo h5:not(:first-child) {
    margin-top: 1.1em; }
  .typo h2 + *, .typo h3 + *, .typo h4 + *, .typo h5 + * {
    margin-top: 0.3em; }
  .typo a:not(.btn) {
    font-weight: 700;
    color: #1C394B; }
    .typo a:not(.btn):hover {
      text-decoration: underline; }
  .typo ul {
    padding-left: 1em; }
  .typo li {
    margin: 0.3em 0;
    position: relative; }
    .typo li::before {
      content: '';
      background-color: #1C394B;
      border-radius: 50%;
      display: inline-block;
      height: 6px;
      width: 6px;
      position: absolute;
      top: .5em;
      left: -12px; }
  .typo .big {
    font-size: 1.28571rem; }
  .typo blockquote {
    color: #1C394B;
    font-size: 1.71429rem;
    font-weight: 700;
    line-height: 1.6;
    margin: 35px 0;
    padding: 2em 0 0;
    position: relative;
    text-align: center; }
    .typo blockquote p {
      margin-top: 0.5em; }
    .typo blockquote small {
      display: block;
      font-size: 1.14286rem;
      font-weight: 400;
      line-height: 1.4; }
  .typo p {
    font-size: 1.14286rem; }

.title-section {
  padding: 25px 0; }

@media all and (min-width: 64em) {
  .typo .big {
    font-size: 1.57143rem; }
  .typo ul {
    padding-left: 2.85714rem; }
  .typo blockquote {
    font-size: 2rem;
    margin: 60px 0;
    padding: 0 0 0 4em;
    text-align: right; }
    .typo blockquote::before {
      left: 0;
      margin: 0;
      top: -0.5em; }
    .typo blockquote small {
      font-size: 1.14286rem; }
  .typo h4 {
    margin-bottom: 2.14286rem; } }

/**
 * ==================== @ ICONS
 */
.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

.icon-arrow {
  width: 2.32421875em; }

.icon-list-marker {
  width: 1.0556640625em; }

.icon-lightbulb {
  width: 0.970703125em; }

.icon-steps {
  width: 0.81640625em; }

.icon-brittany {
  width: 0.87109375em; }

.icon-group {
  width: 2.3232421875em; }

.icon-title-deco {
  width: 1.0517578125em; }

.icon-home {
  width: 1.205078125em; }

.icon-long-arrow {
  width: 0.140625em; }

.icon-download {
  width: 0.876953125em; }

.icon-chevron {
  width: 1.8779296875em; }

.icon-container {
  display: inline-block;
  height: 1em;
  width: 1em; }
  .icon-container .icon {
    height: inherit;
    width: inherit; }

/**
 * ==================== @ FORMS
 */
label {
  color: #1C394B;
  display: block;
  font-size: 1.14286rem;
  font-weight: 400;
  padding: 14px 0; }

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="telephone"]::placeholder,
textarea::placeholder {
  color: #778D9A;
  opacity: .6;
  font-style: italic; }

input[type="text"],
input[type="email"],
input[type="telephone"],
textarea {
  color: rgba(119, 141, 154, 0.6);
  border: solid 1px #1C394B;
  padding: 20px 17px;
  font-size: 16px; }

.select2-container--default .select2-selection--single {
  background-color: white;
  border: solid 1px #1C394B;
  font-size: 16px;
  height: 61px;
  border-radius: 0;
  font-style: italic; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: rgba(112, 112, 112, 0.43);
  line-height: normal; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: rgba(112, 112, 112, 0.43);
  padding: 20px 17px;
  line-height: normal; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 32'%3E%3Cpath fill='%23707070' d='M30.053 32l-30.053-30.053 1.947-1.947 28.106 28.106 28.106-28.106 1.947 1.947z'%3E%3C/path%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  top: calc(50% - 13px);
  right: 20px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none; }

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #1C394B; }

.input-wrapper > * {
  width: 100%; }

.input-wrapper > .select2-container {
  min-width: 100%; }

.file-upload-button {
  background-color: white;
  display: inline-block;
  text-align: left;
  border: solid 1px #1C394B;
  font-size: 16px;
  font-style: italic;
  color: rgba(112, 112, 112, 0.43);
  padding: 20px 17px;
  position: relative; }
  .file-upload-button .svg-icon,
  .file-upload-button span {
    display: inline-block;
    vertical-align: middle; }
  .file-upload-button .svg-icon {
    color: #1C394B; }
  .file-upload-button span {
    width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.file-upload-button + input {
  display: none; }

input[type="checkbox"],
input[type="radio"] {
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  appearance: none;
  vertical-align: text-bottom;
  outline: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 1px #1C394B;
  background-color: #FFF;
  transition: background-color .15s;
  margin-top: 14px; }
  input[type="checkbox"]::-ms-check,
  input[type="radio"]::-ms-check {
    display: none; }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 32'%3E%3Cpath fill='%23778D9A' d='M17.411 28.785l-1.544-1.542-13.269-13.21 3.041-3.262 11.723 11.675 15.353-15.803 3.091 3.211z'%3E%3C/path%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 80%;
    background-color: white;
    box-shadow: inset 0 0 0 1px #1C394B; }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: inline-block;
    max-width: calc(100% - 30px);
    padding-left: .5rem;
    cursor: pointer;
    vertical-align: top; }

textarea {
  resize: none; }

.submit-wrapper .btn, .submit-wrapper .text-img .entry a, .text-img .entry .submit-wrapper a {
  text-align: left;
  width: 100%; }

.alert {
  font-size: 22px;
  text-align: center;
  white-space: normal;
  padding: 24px 0; }

@media all and (min-width: 64em) {
  .file-upload-button::before {
    content: 'Choisir un fichier';
    background-color: #1C394B;
    color: white;
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    padding: 20px;
    font-style: normal; }
  .file-upload-button span {
    padding-left: 175px;
    opacity: 0; }
  .input-wrapper.m--half {
    display: inline-block;
    width: 50%; }
  form {
    white-space: nowrap; }
    form .input-wrapper {
      white-space: normal; }
  .submit-wrapper .btn, .submit-wrapper .text-img .entry a, .text-img .entry .submit-wrapper a {
    width: auto; }
  .submit-wrapper {
    display: flex;
    align-items: flex-start; }
    .submit-wrapper .btn, .submit-wrapper .text-img .entry a, .text-img .entry .submit-wrapper a {
      margin-top: 14px; } }

/**
 * ==================== @ SLIDERS
 */
.swiper-wrapper {
  box-sizing: border-box; }

/**
 * ==================== @ GENERIC MODIFIERS
 */
.m--title-deco, .typo h2, .typo h3, .typo h4 {
  padding: 32px 0;
  position: relative; }
  .m--title-deco::before, .typo h2::before, .typo h3::before, .typo h4::before {
    content: '';
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 32'%3E%3Cpath fill='%23778D9A' d='M10.28 16.821s4.673-15.887 17.756-16.821c0 0-1.869 14.018-17.756 16.821z'%3E%3C/path%3E%3Cpath fill='%23778D9A' d='M33.643 18.69s-3.937 7.889-13.932 11.214c-5.792 1.53-13.798 2.801-19.71 0 0 0 8.411-15.887 33.643-11.214z'%3E%3C/path%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .15;
    position: absolute;
    display: block;
    height: 100px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100px;
    z-index: 10; }
  .m--title-deco.m--white::before, .typo h2.m--white::before, .typo h3.m--white::before, .typo h4.m--white::before {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 32'%3E%3Cpath fill='white' d='M10.28 16.821s4.673-15.887 17.756-16.821c0 0-1.869 14.018-17.756 16.821z'%3E%3C/path%3E%3Cpath fill='white' d='M33.643 18.69s-3.937 7.889-13.932 11.214c-5.792 1.53-13.798 2.801-19.71 0 0 0 8.411-15.887 33.643-11.214z'%3E%3C/path%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }

.m--bg-dark {
  background: #1C394B;
  color: white; }

.job-offer-head .job-offer-head__contract-type {
  font-size: 35px;
  font-weight: 700; }

.job-offer-head .job-offer__location-city {
  font-size: 22px;
  font-weight: 700;
  display: block; }

.job-offer-head .job-offer__location-department {
  font-size: 12px;
  font-weight: 700;
  display: block; }

.job-offer-head .job-offer-head__company {
  font-size: 22px;
  font-weight: 700; }

.job-offer-head .job-offer-head__job-infos {
  margin-top: 30px; }
  .job-offer-head .job-offer-head__job-infos .svg-icon {
    display: none; }
  .job-offer-head .job-offer-head__job-infos span {
    display: block;
    font-size: 22px;
    line-height: 30px; }

.job-offer-head .job-offer-head__company {
  display: block; }

.job-offer__content p, .job-offer__content ul {
  font-size: 22px;
  line-height: 30px; }

.job-offer__content .h3-like, .job-offer__content .typo h3, .typo .job-offer__content h3 {
  font-size: 25px;
  padding: 30px 0; }

.job-offer__content p.m--ptop {
  padding-top: 32px; }

.job-offer-head__line {
  border-bottom: solid 1px #1C394B;
  padding: 26px 0; }

.job-offer__wrapper {
  padding: 0 36px; }

.job-offer-form, .contact-form {
  background-position: center;
  background-size: cover;
  padding: 36px;
  position: relative; }
  .job-offer-form::before, .contact-form::before {
    content: "";
    background: url("/assets/images/line-bg-white.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    opacity: .24; }
  .job-offer-form::after, .contact-form::after {
    content: "";
    display: block;
    background: linear-gradient(180deg, rgba(119, 141, 154, 0.14), rgba(119, 141, 154, 0.06) 60%, rgba(119, 141, 154, 0) 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }
  .job-offer-form .l-wrapper, .contact-form .l-wrapper {
    position: relative;
    z-index: 1; }

@media all and (min-width: 64em) {
  .job-offer__wrapper {
    background-color: #fff;
    padding: 72px 92px 92px;
    position: relative;
    transform: translateY(-176px);
    margin-bottom: -176px;
    z-index: 1; }
  .job-offer-head .job-offer-head__line {
    display: flex;
    align-items: center; }
  .job-offer-head .job-offer__location {
    display: flex;
    flex-direction: column-reverse; }
  .job-offer-head .job-offer-head__job-infos {
    margin-top: 0;
    justify-self: flex-end;
    margin-left: auto; }
    .job-offer-head .job-offer-head__job-infos span {
      display: inline-block; }
    .job-offer-head .job-offer-head__job-infos .svg-icon {
      display: inline-block;
      color: #778D9A;
      margin: 0 15px; }
  .job-offer-head .job-offer-head__company {
    margin-left: auto;
    display: inline-block; } }

.social {
  background-color: #1C394B; }
  .social .crt-widget .crt-post-text {
    color: white;
    text-align: left;
    padding-top: 18px; }
  .social .crt-post-header {
    display: none; }
  .social .crt-post-footer {
    display: none; }

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  min-height: 125px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100; }
  .header .l-wrapper {
    padding: 25px 35px;
    background-color: white;
    border: 1px solid white;
    width: 100%; }
  .header__top {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header .menu-el {
    cursor: pointer; }
    .header .menu-el:first-child {
      transform: translateY(5px); }
    .header .menu-el:last-child {
      transform: translateY(-5px); }
    .header .menu-el svg {
      transform: rotate(23deg) scaleX(2);
      width: 20px; }
  .header .main-menu {
    background-color: white;
    left: 0;
    min-height: calc(100vh - 125px);
    opacity: 0;
    padding: 25px;
    pointer-events: none;
    position: absolute;
    top: 125px;
    width: 100vw;
    z-index: 100; }
    .header .main-menu.m--is-open {
      opacity: 1;
      pointer-events: all;
      transition: opacity 300ms ease; }
    .header .main-menu .top-links {
      font-size: 1.07143rem;
      font-weight: 600; }
      .header .main-menu .top-links__link {
        padding: 15px;
        color: white;
        text-align: center; }
        .header .main-menu .top-links__link.m--blue {
          background: #1C394B; }
        .header .main-menu .top-links__link.m--light-blue {
          background: #778D9A; }
      .header .main-menu .top-links .main-menu__wrapper {
        margin-top: 25px; }
        .header .main-menu .top-links .main-menu__wrapper a {
          text-align: center;
          margin: 20px 0;
          font-size: 1.28571rem; }

@media all and (min-width: 64em) {
  .header {
    align-items: flex-start;
    position: fixed; }
    .header .header__top {
      width: 250px;
      padding: 16px 36px; }
    .header .header__top a img {
      width: 170px; }
    .header .l-wrapper {
      border: 0;
      display: flex;
      justify-content: space-between;
      padding: 0; }
    .header .header__open-menu {
      display: none; }
    .header .main-menu {
      display: block;
      position: static;
      min-height: inherit;
      opacity: 1;
      pointer-events: all;
      width: inherit; }
    .header .main-menu {
      padding: 0; }
      .header .main-menu .top-links {
        display: flex;
        justify-content: flex-end; }
        .header .main-menu .top-links__link {
          padding: 15px 35px;
          width: max-content; }
        .header .main-menu .top-links .main-menu__wrapper {
          display: flex;
          justify-content: flex-end;
          padding: 12px 60px 16px;
          margin-top: 0; }
          .header .main-menu .top-links .main-menu__wrapper a {
            margin: 0px 0px 0px 50px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            width: max-content; }
            .header .main-menu .top-links .main-menu__wrapper a:hover {
              color: #778D9A;
              transition: 300ms ease; } }

.page-header {
  align-items: stretch;
  background: url("/assets/images/line-bg-white.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  display: flex;
  justify-content: center;
  height: 480px;
  padding: 125px 25px 50px 25px;
  position: relative;
  z-index: 1; }
  .page-header:after {
    content: "";
    background-color: #1C394B;
    opacity: .9;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }
  .page-header h1 {
    margin-bottom: 50px;
    transform: translateY(50px);
    animation: slideText 1000ms ease forwards;
    opacity: 0;
    position: relative;
    max-width: 900px; }
    .page-header h1::before {
      content: "";
      height: 8px;
      width: 0;
      background: white;
      position: absolute;
      top: -40px;
      animation: slideRight 500ms ease 800ms forwards; }
  .page-header .l-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .page-header .page-header__title {
    margin-top: auto; }

.tips-arrow {
  background-color: #446876;
  bottom: 0;
  color: white;
  height: 85px;
  position: absolute;
  right: 0;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .tips-arrow svg {
    transform: rotate(90deg);
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    animation: clipPathArrow 3000ms ease-in-out infinite;
    opacity: 0; }
  .tips-arrow .icon-long-arrow {
    display: none; }

@media all and (min-width: 64em) {
  .page-header {
    padding-top: 100px;
    padding-bottom: 176px;
    position: relative;
    height: 550px;
    min-height: 550px; }
    .page-header .l-wrapper {
      position: relative; }
    .page-header .tips-arrow {
      position: absolute;
      left: 100%;
      height: 215px;
      width: 140px;
      font-size: 140px;
      animation: none;
      top: 100%;
      z-index: 1; }
      .page-header .tips-arrow .icon-arrow {
        display: none; }
      .page-header .tips-arrow .icon-long-arrow {
        display: block; }
      .page-header .tips-arrow svg {
        transform: none;
        animation-name: clipPathArrowVert;
        clip-path: polygon(0 0, 0 0, 100% 0, 100% 0); }
    .page-header.m--home {
      height: 640px;
      padding-bottom: 106px;
      min-height: 640px; }
      .page-header.m--home .tips-arrow {
        top: calc(100% + 18px); }
    .page-header:before {
      animation: fadeIn 1500ms ease 1000ms forwards;
      background: url("/assets/images/logo-small.png") no-repeat;
      background-position: 75%;
      background-size: contain;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%; }
    .page-header h1 {
      padding-left: 80px; }
      .page-header h1::before {
        left: 0;
        top: 30px;
        transform: translateY(-50%); }
  .page-header + .text-wide {
    position: relative;
    z-index: 2;
    top: -176px;
    margin-bottom: -176px; }
    .page-header + .text-wide .l-wrapper {
      position: relative;
      background-color: white; } }

#animation-container {
  position: fixed;
  display: none;
  opacity: 1;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  transition: opacity .8s ease;
  left: 0;
  transition-property: opacity, width, left, height, padding; }
  #animation-container.after-anim {
    opacity: 0;
    width: 175px;
    height: 100px; }
    #animation-container.after-anim::before {
      opacity: 0; }
  #animation-container::before {
    content: '';
    background-color: #F7F8F9;
    display: block;
    height: 100%;
    position: absolute;
    transition: opacity .2s ease;
    width: 100%;
    top: 0;
    left: 0; }

@media all and (min-width: 64em) {
  #animation-container {
    padding: 35vh; }
    #animation-container.after-anim {
      padding: 0; } }

@keyframes slideText {
  to {
    opacity: 1;
    transform: translate(0); } }

@keyframes slideRight {
  to {
    width: 60px; } }

@keyframes fadeIn {
  to {
    opacity: 1; } }

@keyframes clipPathArrow {
  0% {
    opacity: 0; }
  40% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1; }
  80% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1; }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 0; } }

@keyframes clipPathArrowVert {
  0% {
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
    opacity: 0; }
  80% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    opacity: 1; }
  100% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    opacity: 1; } }

.video-slider {
  padding-bottom: 120px; }
  .video-slider .swiper {
    min-height: 350px; }
  .video-slider .swiper-wrapper {
    min-height: 230px; }
  .video-slider .swiper-slide {
    align-self: center;
    transition: width .2s linear;
    width: 300px; }
    .video-slider .swiper-slide .video-slider__slide-title {
      opacity: 1;
      transition: opacity .2s linear; }
  .video-slider .swiper-slide:not(.swiper-slide-active) {
    width: 230px; }
    .video-slider .swiper-slide:not(.swiper-slide-active) .video-slider__slide-title {
      opacity: 0; }
  .video-slider .swiper-slide .video-slider__slide-inner {
    opacity: 1;
    transition: opacity .2s ease; }
    .video-slider .swiper-slide .video-slider__slide-inner img {
      display: block; }
    .video-slider .swiper-slide .video-slider__slide-inner::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(119, 141, 154, 0.4);
      display: block;
      position: absolute; }
  .video-slider .swiper-slide:not(.swiper-slide-active) .video-slider__slide-inner {
    opacity: .6; }
  .video-slider .video-slider__slide-title {
    font-size: 14px;
    padding-top: 14px;
    font-weight: 700;
    text-align: center; }
  .video-slider .video-slider__play-btn {
    width: 0px;
    height: 0px;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 40px solid white;
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 20px);
    transform: scale(1);
    transition: transform .2s linear; }
  .video-slider .swiper-slide:not(.swiper-slide-active) .video-slider__play-btn {
    transform: scale(0.66); }
  .video-slider .video-slider__slide-inner {
    position: relative; }
  .video-slider .swiper-horizontal > .swiper-scrollbar {
    max-width: 720px;
    margin: 36px auto 0;
    position: relative;
    height: 10px;
    border-radius: 0;
    clip-path: inset(-100vw 0 -100vw 0); }
    .video-slider .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag {
      background-color: #1C394B;
      border-radius: 25px;
      height: 50px;
      width: 50px !important;
      top: -20px; }
      .video-slider .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag::before {
        content: '';
        background-color: #1C394B;
        height: 10px;
        position: absolute;
        width: 720px;
        left: -719px;
        top: calc(50% - 5px); }
      .video-slider .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag .swiper-scrollbar-drag__deco {
        background-color: #446876;
        height: 8px;
        width: 2px;
        display: inline-block;
        position: absolute;
        left: calc(50% - 1px);
        top: calc(50% - 4px); }
        .video-slider .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag .swiper-scrollbar-drag__deco::before, .video-slider .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag .swiper-scrollbar-drag__deco::after {
          content: '';
          background-color: #446876;
          height: 8px;
          width: 2px;
          display: inline-block;
          position: absolute;
          top: calc(50% - 4px); }
        .video-slider .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag .swiper-scrollbar-drag__deco::before {
          left: calc(50% - 4px); }
        .video-slider .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag .swiper-scrollbar-drag__deco::after {
          left: calc(50% + 2px); }
    .video-slider .swiper-horizontal > .swiper-scrollbar svg {
      position: absolute;
      top: -65px;
      left: -20px; }

@media all and (min-width: 64em) {
  .video-slider {
    margin-top: 110px; }
    .video-slider .swiper {
      overflow: visible;
      min-height: 410px; }
    .video-slider .swiper-wrapper {
      min-height: 410px; }
    .video-slider .swiper-slide {
      width: 640px; }
    .video-slider .swiper-slide:not(.swiper-slide-active) {
      width: 430px; }
    .video-slider .video-slider__play-btn {
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 87px solid white;
      top: calc(50% - 50px);
      left: calc(50% - 43px); }
    .video-slider .video-slider__slide-title {
      font-size: 26px; }
    .video-slider .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag {
      border-radius: 30px;
      height: 60px;
      width: 60px !important;
      top: -25px; } }

.jobs-list {
  margin-bottom: 50px; }
  .jobs-list .jobs-list__title {
    margin-bottom: 60px;
    padding-left: 30px; }
    .jobs-list .jobs-list__title::before {
      left: 30px; }
  .jobs-list .jobs-list__col-left, .jobs-list .jobs-list__location {
    position: relative; }
  .jobs-list .jobs-list__col-left img {
    max-width: 100px; }
  .jobs-list .jobs-list__title-box {
    margin-top: 30px; }
    .jobs-list .jobs-list__title-box h3 {
      font-size: 26px;
      padding: 8px 0; }
  .jobs-list .jobs-list__small {
    font-size: 12px;
    font-weight: 700; }
  .jobs-list .jobs-list__city {
    font-size: 22px; }
  .jobs-list .jobs-list__item-wrapper {
    position: relative;
    padding: 34px 52px 41px 41px;
    background: linear-gradient(180deg, rgba(119, 141, 154, 0.14), rgba(119, 141, 154, 0.06) 60%, rgba(119, 141, 154, 0) 100%); }
  .jobs-list .jobs-list__location-wrapper * {
    display: inline-block;
    vertical-align: middle; }
    .jobs-list .jobs-list__location-wrapper *.jobs-list__small {
      padding-left: 18px; }
  .jobs-list .btn, .jobs-list .text-img .entry a, .text-img .entry .jobs-list a {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 24px 0; }
  .jobs-list .jobs-list__item {
    display: none; }
  .jobs-list .jobs-list__item:nth-child(-n+6) {
    display: block; }

.featured-offers {
  padding: 35px 0; }
  .featured-offers .h2-like, .featured-offers .typo h2, .typo .featured-offers h2 {
    margin-left: 35px;
    margin-bottom: 42px; }

.jobs-list__spontaneous {
  text-align: center;
  padding-top: 32px; }
  .jobs-list__spontaneous .btn, .jobs-list__spontaneous .text-img .entry a, .text-img .entry .jobs-list__spontaneous a {
    left: 0;
    transform: none; }
  .jobs-list__spontaneous p {
    font-size: 20px;
    line-height: 32px; }
  @media all and (min-width: 64em) {
    .jobs-list__spontaneous p {
      font-size: 22px;
      line-height: 30px; } }

@media all and (min-width: 64em) {
  .jobs-list .jobs-list__col-left {
    padding: 40px 16px; }
    .jobs-list .jobs-list__col-left img {
      max-width: 120px; }
  .jobs-list .jobs-list__title-box {
    margin-top: 0;
    padding-left: 30px;
    position: relative; }
  .jobs-list .jobs-list__location {
    padding: 40px 30px; }
  .jobs-list .jobs-list__col-left {
    display: flex;
    width: 65%; }
  .jobs-list .jobs-list__item-wrapper {
    display: flex; }
  .jobs-list .jobs-list__location {
    border-left: solid 6px white; }
  .jobs-list .jobs-list__col-left::before, .jobs-list .jobs-list__location::before {
    content: '';
    opacity: .14;
    position: absolute;
    display: block;
    height: 100%;
    width: 100vw;
    top: 0;
    background: linear-gradient(180deg, #778d9a, rgba(119, 141, 154, 0.73) 60%, rgba(119, 141, 154, 0) 100%);
    transition: background .2s ease; }
  .jobs-list .jobs-list__col-left.jobs-list__col-left::before, .jobs-list .jobs-list__location.jobs-list__col-left::before {
    right: 0; }
  .jobs-list .jobs-list__col-left.jobs-list__location::before, .jobs-list .jobs-list__location.jobs-list__location::before {
    left: 0; }
  .jobs-list .jobs-list__item-wrapper:hover .jobs-list__col-left::before, .jobs-list .jobs-list__item-wrapper:hover .jobs-list__location::before {
    background: white; }
  .jobs-list .jobs-list__item-wrapper {
    background: none;
    padding: 0; }
  .jobs-list .jobs-list__location-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column-reverse; }
    .jobs-list .jobs-list__location-wrapper .jobs-list__small {
      padding-left: 0; } }

.breadcrumb {
  padding-top: 30px;
  display: none; }
  .breadcrumb li {
    display: inline-block; }

@media (min-width: 64em) {
  .breadcrumb {
    display: block; } }

.footer {
  background-color: rgba(119, 141, 154, 0.2);
  padding: 30px 36px 120px;
  position: relative; }
  .footer .tips-arrow {
    top: 0; }

.footer-menu {
  line-height: 50px;
  font-size: 20px; }
  .footer-menu a {
    display: block; }

.footer-menu__first-line {
  font-weight: 700; }

.footer-menu__second-line {
  color: #2E2E2E; }

.footer__logo {
  margin-bottom: 44px; }

.footer__back-to-top .tips-arrow {
  transform: scaleY(-1); }

@media (min-width: 64em) {
  .footer {
    padding: 120px 0px 75px; }
  .footer__logo {
    display: inline-block;
    width: 185px; }
  .footer-menu {
    display: inline-block;
    font-size: 14px;
    text-align: right;
    width: calc(100% - 190px); }
    .footer-menu a {
      display: inline-block; }
  .footer-menu__first-line a:not(:last-child)::after {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 32'%3E%3Cpath fill='%23778D9A' d='M0 32s8.89-30.222 33.78-32c0 0-3.555 26.667-33.78 32z'%3E%3C/path%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 36px;
    position: relative;
    top: 2px; }
  .footer-menu__second-line a:not(:last-child)::after {
    content: ' | '; }
  .footer__back-to-top .tips-arrow {
    transform: translateY(-50%) scaleY(-1);
    font-size: 19px;
    height: 110px;
    width: 140px; } }

.who-we-are__key-figures .who-we-are__figure {
  font-family: 'CCSignLanguage';
  font-size: 67px;
  display: block;
  text-align: center; }

.who-we-are__key-figures .who-we-are__figure-desc {
  color: #446876;
  display: block;
  text-align: center; }

.who-we-are__text {
  padding: 35px; }

.who-we-are__schema {
  padding: 35px; }
  .who-we-are__schema .schema-desktop {
    display: none; }
  .who-we-are__schema .schema-mobile img {
    display: block;
    margin: 0 auto; }

@media all and (min-width: 64em) {
  .who-we-are .l-wrapper {
    background-color: white;
    position: relative; }
    .who-we-are .l-wrapper::before {
      content: '';
      background-color: white;
      top: 0;
      width: 100%;
      position: absolute;
      display: block;
      height: 87px;
      left: 0;
      z-index: 1; }
  .who-we-are__text {
    background-color: rgba(119, 141, 154, 0.06);
    margin-top: -87px;
    padding: 67px 160px 82px 76px;
    position: relative;
    z-index: 2; }
    .who-we-are__text p {
      padding-left: 100px; }
  .who-we-are__schema {
    padding: 40px 70px 40px 76px;
    position: relative; }
    .who-we-are__schema .title-section {
      position: absolute; }
    .who-we-are__schema .schema-desktop {
      display: block; }
    .who-we-are__schema .schema-mobile {
      display: none; }
  .who-we-are__schema::after {
    content: '';
    display: block;
    left: 100%;
    height: 150%;
    bottom: 0;
    background-color: #1C394B;
    position: absolute;
    width: 100%;
    z-index: -1; } }

.services {
  padding: 0 12px; }
  .services .h2-like, .services .typo h2, .typo .services h2 {
    padding-left: 24px;
    padding-bottom: 68px; }
    .services .h2-like::before, .services .typo h2::before, .typo .services h2::before {
      left: 68px;
      top: calc(50% - 21px); }
  .services .services__service {
    color: white;
    text-align: center;
    padding: 60px 60px 40px; }
    .services .services__service.m--gray {
      background-color: #ECEFF1;
      color: #1C394B; }
    .services .services__service.m--light-blue {
      background-color: #446876; }
    .services .services__service.m--blue {
      background-color: #1C394B; }
    .services .services__service h4 {
      font-size: 30px;
      font-weight: 700;
      padding-bottom: 50px; }
    .services .services__service ul {
      font-size: 20px;
      line-height: 24px; }
      .services .services__service ul li {
        padding-bottom: 32px; }

@media all and (min-width: 64em) {
  .services {
    padding: 120px 0 110px; }
    .services .services__wrapper {
      display: flex;
      flex-wrap: wrap; }
    .services .title-section {
      width: 100%;
      padding-left: 0;
      text-align: center; }
      .services .title-section::before {
        left: calc(50% - 50px); }
    .services .services__service {
      transform: translateY(-90px);
      width: calc(33.33% - 16px);
      margin: 0 8px; }
      .services .services__service:nth-child(3) {
        transform: translateY(0); } }

.map {
  padding-left: 22px; }
  .map .sv-u-1:last-child {
    pointer-events: none; }
  .map .map__map {
    transform: translateY(-25%);
    margin-bottom: -40%;
    overflow: hidden; }
    .map .map__map svg {
      max-width: 150%; }
      .map .map__map svg #brest, .map .map__map svg #plabennec, .map .map__map svg #morlaix, .map .map__map svg #saint-brieuc, .map .map__map svg #rennes, .map .map__map svg #nantes, .map .map__map svg #vannes, .map .map__map svg #lorient, .map .map__map svg #quimper {
        transition: opacity .2s ease; }
      .map .map__map svg.m--groupe #brest, .map .map__map svg.m--groupe #morlaix, .map .map__map svg.m--groupe #saint-brieuc, .map .map__map svg.m--groupe #rennes, .map .map__map svg.m--groupe #nantes, .map .map__map svg.m--groupe #vannes, .map .map__map svg.m--groupe #lorient, .map .map__map svg.m--groupe #quimper {
        opacity: 0; }
      .map .map__map svg.m--energie .logo-leaf {
        fill: #B4C422 !important; }
      .map .map__map svg.m--energie #plabennec {
        opacity: 0; }
      .map .map__map svg.m--habitat .logo-leaf {
        fill: #EF710C !important; }
      .map .map__map svg.m--habitat #plabennec, .map .map__map svg.m--habitat #morlaix, .map .map__map svg.m--habitat #vannes {
        opacity: 0; }
  .map .map__text {
    padding: 40px 36px 50px 14px;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px; }
  .map .map__filters {
    margin-top: 35px; }
    .map .map__filters h4 {
      margin-bottom: 14px; }
    .map .map__filters button {
      background-color: transparent;
      border: solid 1px #1C394B;
      color: #1C394B;
      display: block;
      font-weight: 700;
      margin-bottom: 8px;
      padding: 14px 22px;
      transition: color .2s ease;
      transition-property: color, background-color; }
      .map .map__filters button:hover, .map .map__filters button.m--active {
        background-color: #1C394B;
        color: white; }

@media all and (min-width: 64em) {
  .map {
    padding: 100px 0;
    background: linear-gradient(180deg, rgba(119, 141, 154, 0.14), rgba(119, 141, 154, 0.1) 60%, rgba(119, 141, 154, 0) 100%); }
    .map .map__map {
      transform: none;
      position: relative;
      top: -100px;
      overflow: visible; }
    .map .map__text {
      padding-right: 80px; }
    .map .map__filters {
      margin-top: 85px;
      width: 200%; }
      .map .map__filters h4, .map .map__filters button {
        display: inline-block; }
      .map .map__filters h4 {
        margin-right: 40px; }
      .map .map__filters button {
        margin-right: 9px; } }

.picto-list {
  background: url("/assets/images/line-bg-white.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  position: relative;
  padding: 50px 20px; }
  .picto-list .h2-like, .picto-list .typo h2, .typo .picto-list h2 {
    padding-left: 20px; }
    .picto-list .h2-like::before, .picto-list .typo h2::before, .typo .picto-list h2::before {
      left: 20px; }
  .picto-list .l-wrapper {
    position: relative; }
  .picto-list .sv-g {
    margin: 0 -10px; }
    .picto-list .sv-g div[class^='sv-u'] {
      padding: 0 10px; }
      .picto-list .sv-g div[class^='sv-u']:last-child {
        margin: 0 auto; }
  .picto-list:before {
    content: "";
    background-color: #1C394B;
    opacity: .9;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }
  .picto-list .svg-icon {
    font-size: 90px;
    display: block;
    margin: 0 auto;
    color: #778D9A; }
    .picto-list .svg-icon.icon-group {
      font-size: 50px;
      margin: 20px auto; }
  .picto-list .picto-list__picto {
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px; }
    .picto-list .picto-list__picto span {
      display: inline-block;
      padding-top: 18px; }

@media all and (min-width: 64em) {
  .picto-list .l-wrapper::after {
    content: '';
    background-color: white;
    display: block;
    position: absolute;
    height: calc(100% + 100px);
    width: 100vw;
    top: -50px;
    left: 100%; }
  .picto-list .l-wrapper {
    padding-right: 120px; } }

.text-img {
  position: relative; }
  .text-img .entry {
    padding: 40px 36px 50px; }
  .text-img svg {
    left: 50%;
    position: absolute;
    bottom: 0;
    min-width: 350px;
    fill: #1C394B;
    z-index: -1; }
  .text-img .grid-col-txt {
    position: relative;
    overflow: hidden; }
  .text-img .grid-col-pic picture, .text-img .grid-col-pic img {
    display: block;
    margin: 0 auto; }

@media all and (min-width: 64em) {
  .text-img .entry {
    padding: 40px 12px 50px; }
  .text-img svg {
    left: 20%;
    top: 36px;
    min-width: 485px;
    height: auto;
    bottom: initial; } }

.text-wide p {
  padding: 38px;
  font-size: 20px;
  line-height: 32px; }
  .text-wide p a {
    font-size: 16px; }

@media all and (min-width: 64em) {
  .text-wide p {
    padding: 74px 92px;
    font-size: 22px;
    line-height: 30px; }
    .text-wide p a {
      font-size: 16px; } }
