/**
 * ==================== @ FORMS
 */

%label {
  color: $blue;
  display: block;
  font-size: rem(16);
  font-weight: $fw-regular;
  padding: 14px 0;
}

%placeholder {
  color: $blue-gray;
  opacity: .6;
  font-style: italic;
}

%focus {
  background-color: $gray-l;
  color: $blue;
}

label {
  @extend %label;
}

input[type="text"],
input[type="email"],
input[type="telephone"],
textarea {
  color: rgba($blue-gray, .6);
  border: solid 1px $blue;
  padding: 20px 17px;
  font-size: 16px;

  &::placeholder {
    @extend %placeholder;
  }
}

.select2-container--default .select2-selection--single {
  background-color: white;
  border: solid 1px $blue;
  font-size: 16px;
  height: 61px;
  border-radius: 0;
  font-style: italic;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: rgba($gray-d, .43);
  line-height: normal;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: rgba($gray-d, .43);
  padding: 20px 17px;
  line-height: normal;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  @include background-svg('<svg viewBox="0 0 60 32"><path d="M30.053 32l-30.053-30.053 1.947-1.947 28.106 28.106 28.106-28.106 1.947 1.947z"></path></svg>', $gray-d);
  top: calc(50% - 13px);
  right: 20px;

  b {
    display: none;
  }
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: $blue;
}

.input-wrapper>* {
  width: 100%;
}


.input-wrapper>.select2-container {
  min-width: 100%;
}

.file-upload-button {
  background-color: white;
  display: inline-block;
  text-align: left;
  border: solid 1px $blue;
  font-size: 16px;
  font-style: italic;
  color: rgba($gray-d, .43);
  padding: 20px 17px;
  position: relative;
  .svg-icon,
  span {
    display: inline-block;
    vertical-align: middle;
  }

  .svg-icon {
    color: $blue;
  }

  span {
    width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.file-upload-button+input {
  display: none;
}

input[type="checkbox"],
input[type="radio"] {
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  appearance: none;
  vertical-align: text-bottom;
  outline: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 1px $blue;
  background-color: #FFF;
  transition: background-color .15s;
  margin-top: 14px;
  &::-ms-check {
    display: none; // unstyle IE checkboxes
  }

  &:checked {
    @include background-svg($checkmark, $blue-gray);
    background-size: 80%;
    background-color: white;
    box-shadow: inset 0 0 0 1px $blue;
  }

  +label {
    display: inline-block;
    max-width: calc(100% - 30px);
    padding-left: .5rem;
    cursor: pointer;
    vertical-align: top;
  }
}

textarea {
  resize: none;
}
.submit-wrapper .btn {
  text-align: left;
  width: 100%;
}

.alert {
  font-size: 22px;
  text-align: center;
  white-space: normal;
  padding: 24px 0;
}

@media all and (min-width: $bp-l){
  .file-upload-button::before {
    content: 'Choisir un fichier';
    background-color: $blue;
    color: white;
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    padding: 20px;
    font-style: normal;
  }
  .file-upload-button span {
    padding-left: 175px;
    opacity: 0;
  }
  .input-wrapper.m--half {
    display: inline-block;
    width: 50%;
  }
  form {
    white-space: nowrap;
    .input-wrapper {
      white-space: normal;
    }
  }
  .submit-wrapper .btn {
    width: auto;
  }
  .submit-wrapper {
    display: flex;
    align-items: flex-start;
    .btn {
      margin-top: 14px;
    }
  }

}
