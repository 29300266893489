.text-img {
  position: relative;
  .entry {
    padding: 40px 36px 50px;
    a {
      @extend .btn;
    }
  }
  svg {
    left: 50%;
    position: absolute;
    bottom: 0;
    min-width: 350px;
    fill: $blue;
    z-index: -1;
  }
  .grid-col-txt {
    position: relative;
    overflow: hidden;
  }
  .grid-col-pic {
    picture, img {
      display: block;
      margin: 0 auto;
    }
  }
}

@media all and (min-width: $bp-l){
  .text-img {
    .entry {
      padding: 40px 12px 50px;
    }
    svg {
      left: 20%;
      top: 36px;
      min-width: 485px;
      height: auto;
      bottom: initial;
    }
  }
}
