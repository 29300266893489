/**
 * ==================== @ GENERIC FLOW TOOLS
 */

.l-clearfix {
  @extend %clearfix;
}

.l-wrapper {
  margin: 0 auto;
  max-width: 1080px;

  &.m--s {
    max-width: 590px;
  }
}

.vc_row.l-wrapper {
  margin: 0 auto!important;
}
.l-padding {
  padding: 0 36px;
}

.l-unpad {
  margin: 0 -32px;
}

.l-center {
  text-align: center;
}

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  // &.m--rXXX {} // Ratio modifier
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.l-section {
  padding: 45px 35px;
}

.l-scrollbar {
  position: relative;

  &.ps {
    .ps__rail-y {
      background-color: rgba($gray, 0.5);
      border-radius: 15px;
      left: 0;
      opacity: 1;
      overflow: hidden;
      right: auto;
      transition: width .25s;
      width: 6px;

      &:hover,
      &:focus,
      &.ps--clicking {
        width: 15px;
      }
    }

    .ps__thumb-y {
      background-color: $blue;
      right: 0;
      width: 100%;
    }
  }

  &.m--right {
    .ps__rail-y {
      left: auto;
      right: 0;
    }
  }
}

.l-mobile-hidden {
  display: none!important;
}

body.overlay {
  overflow: hidden;
}

@media all and (min-width: $bp-l) {
  .l-desktop-hidden {
    display: none!important;
  }
  .l-mobile-hidden {
    display: inline-block!important;
  }
}
