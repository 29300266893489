.breadcrumb {
  padding-top: 30px;
  display: none;
  li {
    display: inline-block;
  }
}

@media (min-width: $bp-l) {
  .breadcrumb {
    display: block;
  }
}
