.footer {
  background-color: rgba($blue-gray, .2);
  padding: 30px 36px 120px;
  position: relative;
  .tips-arrow {
    top: 0;
  }
}

.footer-menu {
  line-height: 50px;
  font-size: 20px;
  a {
    display: block;
  }
}

.footer-menu__first-line {
  font-weight: $fw-bold;
}

.footer-menu__second-line {
  color: #2E2E2E;
}

.footer__logo {
  margin-bottom: 44px;
}

.footer__back-to-top {
  .tips-arrow {
    transform: scaleY(-1);
  }
}

@media (min-width: $bp-l){
  .footer {
    padding: 120px 0px 75px;
  }
  .footer__logo {
    display: inline-block;
    width: 185px;
  }
  .footer-menu {
    display: inline-block;
    font-size: 14px;
    text-align: right;
    width: calc(100% - 190px);
    a {
      display: inline-block;
    }
  }
  .footer-menu__first-line a:not(:last-child)::after {
    @include background-svg('<svg viewBox="0 0 34 32"><path d="M0 32s8.89-30.222 33.78-32c0 0-3.555 26.667-33.78 32z"></path></svg>', $blue-gray);
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 36px;
    position: relative;
    top: 2px;
  }
  .footer-menu__second-line a:not(:last-child)::after {
    content: ' | ';
  }
  .footer__back-to-top {
    .tips-arrow {
      transform: translateY(-50%) scaleY(-1);
      font-size: 19px;
      height: 110px;
      width: 140px;
    }
  }
}
