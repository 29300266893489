.who-we-are__key-figures {
  .who-we-are__figure {
    font-family: 'CCSignLanguage';
    font-size: 67px;
    display: block;
    text-align: center;
  }
  .who-we-are__figure-desc {
    color: $blue-l;
    display: block;
    text-align: center;
  }
}

.who-we-are__text {
  padding: 35px;
}

.who-we-are__schema {
  padding: 35px;
  .schema-desktop {
    display: none;
  }
  .schema-mobile img {
    display: block;
    margin: 0 auto;
  }
}

@media all and (min-width: $bp-l){
  .who-we-are .l-wrapper {
    background-color: white;
    position: relative;
    &::before {
      content: '';
      background-color: white;
      top: 0;
      width: 100%;
      position: absolute;
      display: block;
      height: 87px;
      left: 0;
      z-index: 1;
    }
  }
  .who-we-are__text {
    background-color: rgba($blue-gray, 0.06);
    margin-top: -87px;
    padding: 67px 160px 82px 76px;
    position: relative;
    z-index: 2;
    p {
      padding-left: 100px;
    }
  }
  .who-we-are__schema {
    padding: 40px 70px 40px 76px;
    position: relative;
    .title-section {
      position: absolute;
    }
    .schema-desktop {
      display: block;
    }
    .schema-mobile {
      display: none;
    }
  }
  .who-we-are__schema::after {
    content: '';
    display: block;
    left: 100%;
    height: 150%;
    bottom: 0;
    background-color: $blue;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}
