/**
 * ==================== @ TYPO
 */

.typo {
  line-height: 1.5;

  * + p {
    margin-top: 1em;
  }

  h2 { @extend .h2-like; @extend .m--title-deco;}
  h3 { @extend .h3-like; @extend .m--title-deco;}
  h4 { @extend .h4-like; @extend .m--title-deco;}

  h2, h3, h4, h5 {

    &:not(:first-child) {
      margin-top: 1.1em;
    }

    & + * {
      margin-top: 0.3em;
    }

  }

  a:not(.btn) {
    font-weight: $fw-bold;
    color: $blue;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    padding-left: 1em;
  }

  li {
    margin: 0.3em 0;
    position: relative;
    &::before {
      content: '';
      background-color: $blue;
      border-radius: 50%;
      display: inline-block;
      height: 6px;
      width: 6px;
      position: absolute;
      top: .5em;
      left: -12px;
    }
  }

  .big {
    font-size: rem(18);
  }

  blockquote {
    color: $blue;
    font-size: rem(24);
    font-weight: $fw-bold;
    line-height: 1.6;
    margin: 35px 0;
    padding: 2em 0 0;
    position: relative;
    text-align: center;

    // &::before {
    //   @include background-svg($svg-quotes, $orange);
    //   content:'';
    //   display: block;
    //   height: 2em;
    //   left: 50%;
    //   margin-left: -1.5625em;
    //   position: absolute;
    //   top: 0;
    //   width: 3.125em;
    // }

    p {
      margin-top: 0.5em;
    }

    small {
      display: block;
      font-size: rem(16);
      font-weight: $fw-regular;
      line-height: 1.4;
    }

  }

  p {
    font-size: rem(16);
  }

}
.title-section {
  padding: 25px 0;
}


@media all and (min-width: $bp-l) {
  .typo {

    .big {
      font-size: rem(22);
    }
    ul {
      padding-left: rem(40);
    }
    blockquote {
      font-size: rem(28);
      margin: 60px 0;
      padding: 0 0 0 4em;
      text-align: right;

      &::before {
        left: 0;
        margin: 0;
        top: -0.5em;
      }

      small {
        font-size: rem(16);
      }
    }
    h4 {
      margin-bottom: rem(30);
    }
  }

}
