.page-header {
  align-items: stretch;
  background: url('/assets/images/line-bg-white.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  display: flex;
  justify-content: center;
  height: 480px;
  padding: 125px 25px 50px 25px;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    background-color: $blue;
    opacity: .9;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  h1 {
    margin-bottom: 50px;
    transform: translateY(50px);
    animation: slideText 1000ms ease forwards;
    opacity: 0;
    position: relative;
    max-width: 900px;
    &::before {
      content: "";
      height: 8px;
      width: 0;
      background: white;
      position: absolute;
      top: -40px;
      animation: slideRight 500ms ease 800ms forwards;
    }
  }
  .l-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .page-header__title {
    margin-top: auto;
  }
}

.tips-arrow {
  background-color: $blue-gray-2;
  bottom: 0;
  color: white;
  height: 85px;
  position: absolute;
  right: 0;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: rotate(90deg);
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    animation: clipPathArrow 3000ms ease-in-out infinite;
    opacity: 0;
  }
  .icon-long-arrow {
    display: none;
  }
}


@media all and (min-width : $bp-l) {
  .page-header {
    .l-wrapper {
      position: relative;
    }
    .tips-arrow {
      position: absolute;
      left: 100%;
      height: 215px;
      width: 140px;
      font-size: 140px;
      animation: none;
      top: 100%;
      z-index: 1;
      .icon-arrow {
        display: none;
      }
      .icon-long-arrow {
        display: block;
      }
      svg {
        transform: none;
        animation-name: clipPathArrowVert;
        clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
      }
    }
    padding-top: 100px;
    padding-bottom: 176px;
    position: relative;
    height: 550px;
    min-height: 550px;
    &.m--home {
      height: 640px;
      padding-bottom: 106px;
      min-height: 640px;
      .tips-arrow {
        top: calc(100% + 18px);
      }
    }
    &:before {
      animation: fadeIn 1500ms ease 1000ms forwards;
      background: url('/assets/images/logo-small.png') no-repeat;
      background-position: 75%;
      background-size: contain;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }

    h1 {
      padding-left: 80px;
      &::before {
      left: 0;
      top: 30px;
      transform: translateY(-50%);
      }
    }
  }
  .page-header + .text-wide {
    position: relative;
    z-index: 2;
    top: -176px;
    margin-bottom: -176px;
    .l-wrapper {
      position: relative;
      background-color: white;
    }
  }
}

#animation-container {
  position: fixed;
  display: none;
  opacity: 1;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  transition: opacity .8s ease;
  left: 0;
  transition-property: opacity, width, left, height, padding;
  &.after-anim {
    opacity: 0;
    width: 175px;
    height: 100px;
    &::before {
      opacity: 0;
    }
  }
  &::before {
    content: '';
    background-color: #F7F8F9;
    display: block;
    height: 100%;
    position: absolute;
    transition: opacity .2s ease;
    width: 100%;
    top: 0;
    left: 0;
  }
}

@media all and (min-width: $bp-l){
  #animation-container {
    padding: 35vh;
    &.after-anim {
      padding: 0;
    }
  }
}

@keyframes slideText {
  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes slideRight {
  to {
    width: 60px;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes clipPathArrow {
  0% {
    opacity: 0;
  }
  40% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  80% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    opacity: 0;
  }
}

@keyframes clipPathArrowVert {
  0% {
    clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
    opacity: 0;
  }
  80% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    opacity: 1;
  }
  100% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    opacity: 1;
  }
}
