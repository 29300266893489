/**
 * ==================== @ ICONS
 */

.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
.icon-arrow {
  width: 2.32421875em;
}

.icon-list-marker {
  width: 1.0556640625em;
}

.icon-lightbulb {
  width: 0.970703125em;
}

.icon-steps {
  width: 0.81640625em;
}

.icon-brittany {
  width: 0.87109375em;
}

.icon-group {
  width: 2.3232421875em;
}

.icon-title-deco {
  width: 1.0517578125em;
}

.icon-home {
  width: 1.205078125em;
}

.icon-long-arrow {
  width: 0.140625em;
}
.icon-download {
  width: 0.876953125em;
}
.icon-chevron {
  width: 1.8779296875em;
}
.icon-container {
  display: inline-block;
  height: 1em;
  width: 1em;

  .icon {
    height: inherit;
    width: inherit;
  }
}
