.picto-list {
  background: url('/assets/images/line-bg-white.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  position: relative;
  padding: 50px 20px;
  .h2-like {
    padding-left: 20px;
    &::before {
      left: 20px;
    }
  }
  .l-wrapper {
    position: relative;
  }
  .sv-g {
    margin: 0 -10px;
    div[class^='sv-u'] {
      padding: 0 10px;
      &:last-child{
        margin: 0 auto;
      }
    }
  }
  &:before {
    content: "";
    background-color: $blue;
    opacity: .9;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
  .svg-icon {
    font-size: 90px;
    display: block;
    margin: 0 auto;
    color: $blue-gray;
    &.icon-group {
      font-size: 50px;
      margin: 20px auto;
    }
  }
  .picto-list__picto {
    text-align: center;
    font-weight: $fw-bold;
    margin-bottom: 40px;
    span {
      display: inline-block;
      padding-top: 18px;
    }
  }
}

@media all and (min-width: $bp-l){
  .picto-list {
    .l-wrapper::after {
      content: '';
      background-color: white;
      display: block;
      position: absolute;
      height: calc(100% + 100px);
      width: 100vw;
      top: -50px;
      left: 100%;
    }
    .l-wrapper {
      padding-right: 120px;
    }
  }
}
