/**
 * ==================== @ THEME VARIABLES
 */

// --- Breakpoints
$bp-s: 40em;      // 640px
$bp-m: 48em;      // 768px
$bp-l: 64em;      // 1024px
$bp-1280: 80em;    // 1280px
$bp-xl: 85.375em; // 1366px
$bp-xxl: 100em;   // 1600px

// --- Fonts
$icons-fontname: 'icons';
$default-font-size: 14px;
$default-font-stack: 'Montserrat', sans-serif;

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

// --- Colors
$black: #231F20;
$blue: #1C394B;
$blue-l: #446876;
$blue-gray: #778D9A;
$blue-gray-2: #446876;
$blue-green: #0094A8;
$orange: #F0551F;
$yellow: #FECA00;
$gray: #BCBCBC;
$gray-l: #F7FBFE;
$gray-d: #707070;

// --- Gradients

// --- Icons
$arrow: '<svg viewBox="0 0 74 32"><path d="M-0.013 16.011c0.007 0.437 0.363 0.788 0.8 0.788 0.004 0 0.009-0 0.013-0h72.789c0.003 0 0.007 0 0.011 0 0.442 0 0.8-0.358 0.8-0.8s-0.358-0.8-0.8-0.8c-0.004 0-0.008 0-0.012 0h-72.789c-0.004-0-0.008-0-0.013-0-0.442 0-0.8 0.358-0.8 0.8 0 0.004 0 0.009 0 0.013v-0.001z"></path><path d="M57.911 30.876c0 0.442 0.358 0.8 0.8 0.8 0.226 0 0.429-0.093 0.575-0.244l14.867-14.867c0.145-0.145 0.234-0.345 0.234-0.566s-0.090-0.421-0.234-0.566l-14.867-14.867c-0.146-0.152-0.351-0.246-0.577-0.246-0.442 0-0.8 0.358-0.8 0.8 0 0.227 0.094 0.431 0.246 0.577l14.301 14.301-14.301 14.301c-0.15 0.146-0.244 0.35-0.244 0.575 0 0 0 0 0 0v0z"></path></svg>';
$title_deco: '<svg viewBox="0 0 34 32"><path d="M10.28 16.821s4.673-15.887 17.756-16.821c0 0-1.869 14.018-17.756 16.821z"></path><path d="M33.643 18.69s-3.937 7.889-13.932 11.214c-5.792 1.53-13.798 2.801-19.71 0 0 0 8.411-15.887 33.643-11.214z"></path></svg>';
$checkmark: '<svg viewBox="0 0 36 32"><path d="M17.411 28.785l-1.544-1.542-13.269-13.21 3.041-3.262 11.723 11.675 15.353-15.803 3.091 3.211z"></path></svg>';
