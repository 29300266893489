.video-slider {
  padding-bottom: 120px;
  .swiper {
    min-height: 350px;
  }
  .swiper-wrapper {
    min-height: 230px;
  }
  .swiper-slide {
    align-self: center;
    transition: width .2s linear;
    width: 300px;
    .video-slider__slide-title {
      opacity: 1;
      transition: opacity .2s linear;
    }
  }
  .swiper-slide:not(.swiper-slide-active) {
    width: 230px;
    .video-slider__slide-title {
      opacity: 0;
    }
  }
  .swiper-slide .video-slider__slide-inner{
    opacity: 1;
    transition: opacity .2s ease;
    img {
      display: block;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba($blue-gray, .4);
      display: block;
      position: absolute;
    }
  }
  .swiper-slide:not(.swiper-slide-active) .video-slider__slide-inner{
    opacity: .6;
  }
  .video-slider__slide-title {
    font-size: 14px;
    padding-top: 14px;
    font-weight: $fw-bold;
    text-align: center;
  }
  .video-slider__play-btn {
    width: 0px;
    height: 0px;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 40px solid white;
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 20px);
    transform: scale(1);
    transition: transform .2s linear;
  }
  .swiper-slide:not(.swiper-slide-active) {
    .video-slider__play-btn {
      transform: scale(.66);
    }
  }
  .video-slider__slide-inner {
    position: relative;
  }
  .swiper-horizontal > .swiper-scrollbar {
    max-width: 720px;
    margin: 36px auto 0;
    position: relative;
    height: 10px;
    border-radius: 0;
    clip-path: inset(-100vw 0 -100vw 0);
    .swiper-scrollbar-drag {
      background-color: $blue;
      border-radius: 25px;
      height: 50px;
      width: 50px!important;
      top: -20px;
      &::before {
        content: '';
        background-color: $blue;
        height: 10px;
        position: absolute;
        width: 720px;
        left: -719px;
        top: calc(50% - 5px);
      }
      .swiper-scrollbar-drag__deco {
        background-color: $blue-l;
        height: 8px;
        width: 2px;
        display: inline-block;
        position: absolute;
        left: calc(50% - 1px);
        top: calc(50% - 4px);
        &::before, &::after {
          content: '';
          background-color: $blue-l;
          height: 8px;
          width: 2px;
          display: inline-block;
          position: absolute;
          top: calc(50% - 4px);
        }
        &::before {
          left: calc(50% - 4px);
        }
        &::after {
          left: calc(50% + 2px);
        }
      }
    }
    svg {
      position: absolute;
      top: -65px;
      left: -20px;
    }
  }
}

@media all and (min-width: $bp-l){
  .video-slider {
    margin-top: 110px;
    .swiper {
      overflow: visible;
      min-height: 410px;
    }
    .swiper-wrapper {
      min-height: 410px;
    }
    .swiper-slide {
      width: 640px;
    }
    .swiper-slide:not(.swiper-slide-active) {
      width: 430px;
    }
    .video-slider__play-btn {
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 87px solid white;
      top: calc(50% - 50px);
      left: calc(50% - 43px);
    }
    .video-slider__slide-title {
      font-size: 26px;
    }
    .swiper-horizontal > .swiper-scrollbar {
      .swiper-scrollbar-drag {
        border-radius: 30px;
        height: 60px;
        width: 60px!important;
        top: -25px;
      }
    }
  }
}
